import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ListItemText from '@material-ui/core/ListItemText';
import ShoppingBasketTwoToneIcon from '@material-ui/icons/ShoppingBasketTwoTone';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { SoboContact } from 'interfaces';
import { Hidden } from '@material-ui/core';
import { LinkedAccount } from '../../operations/queries/getLinkedAccounts';

interface Props {
  contact: LinkedAccount;
  handleSetSobo: (contact: LinkedAccount) => void;
}

// interface Props {
//   contact: any;
//   handleSetSobo: (contact: any) => void;
// }

const SoboAccountListItem: React.FC<Props> = ({ contact, handleSetSobo }) => {
  const handleSoboClick = () => handleSetSobo(contact);
  return (
    <>
      <ListItem>
        <Hidden smDown>
          <ListItemIcon>
            <PermContactCalendarIcon />
          </ListItemIcon>
        </Hidden>
        <ListItemText primary={`${contact.name}`} secondary={contact.code} />
        {contact.hasSoboCart?

          <ListItemIcon>
            <ShoppingBasketTwoToneIcon color="primary" />
          </ListItemIcon>

        :null}
        <Button color="default" variant="contained" onClick={handleSoboClick}>
          Select
        </Button>
      </ListItem>
      <Divider />
    </>
  );
};

export default SoboAccountListItem;
