import { AUTH_TOKEN_NAME, REFRESH_TOKEN_NAME } from 'helpers/constants';
// import { LoginSuccessEvent } from 'interfaces';
import { decodeToken } from 'services';
import { DecodedToken } from '../interfaces';
import { login_login } from 'operations/mutations/__generated__/login';

const tokenStorage: Storage = localStorage;
interface clearTokenOption {
  redirectToRoot: boolean;
}

const setAuthInfo = (loginData: login_login) => {
  // TODO: Modify graphql schema to correct type
  tokenStorage.setItem(AUTH_TOKEN_NAME, loginData?.accessToken?.token || "");
};
const getAuthToken = () => {
  const token = tokenStorage.getItem(AUTH_TOKEN_NAME);
  return token;
};

export const getUserFromToken = (token: string | null): DecodedToken | null => {
  if (!token) {
    return null;
  }
  const user = decodeToken(token);
  return user;
};
const getRefreshToken = () => {
  const refreshToken = tokenStorage.getItem(REFRESH_TOKEN_NAME);
  return refreshToken;
};
const clearToken = (options: clearTokenOption = { redirectToRoot: true }) => {
  tokenStorage.removeItem(AUTH_TOKEN_NAME);
  if (options.redirectToRoot && window.location.pathname !== '/') {
    if (window !== undefined) {
      window.location.href = '/';
    }
  }
};

const tryRefreshToken = (fn: Function) => {
  const token = getAuthToken();
  const refreshToken = getRefreshToken();

  if (token && refreshToken) {
    fn({
      variables: {
        accessToken: token,
      },
    });
  } else {
    console.log('token issue in try refresh token');
  }
};

export { getAuthToken, clearToken, tryRefreshToken, setAuthInfo };
