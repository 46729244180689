import React, { MouseEvent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Button, MenuItem, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import { MenuNode } from 'interfaces';
import Skeleton from '@material-ui/lab/Skeleton';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';


interface Props {
  menuJson: MenuNode[];
}

const MegaEveMenu: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (node: MenuNode) => {
    if (node && node.id) {
      history.push(`/product-list/${node.seoCrumb}`);
    }

    setAnchorEl(null);
  };



  const propertyId =  "id";
  const propertyParent = "parentId"

  const renderMenu = (parentNode: any,nested:boolean,margin:number) => {

    let subMenu: JSX.Element;
		const id = parentNode[propertyId];
    const children = props.menuJson?.filter((obj:any) => obj.parentId === id).sort((a:any, b:any) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0))


    const menu = children?.map((node: any) => {
      let currentNodeId = node[propertyParent];
      /*
      * If id is equal to the current nodes parentId then render another subMenu
      * Otherwise skip.
      */
      if(currentNodeId === id){
        subMenu = renderMenu(node,true,margin + 20);
      }

      return (
        <div key={node.id} className={classes.category}>
          <MenuItem
            key={node.id}
            style={{marginLeft:`${margin}px`}}
            classes={{
              gutters: classes.menuItemGutters,
              root: classes.menuItemRoot,
            }}
            onClick={(e) => handleClose(node)}
          >
            {node.displayName}
          </MenuItem>
          {currentNodeId === id ? <div className={classes.nestedMenu}> {subMenu}</div>:null}
        </div>
      );
    });
    return (
      <div className={classes.megamenu}>
        <div className={classes.full}>
          {!nested ? (
            <div>
              <Typography variant="h5" component="h3" gutterBottom={true}>
                {`${parentNode?.name}`}
              </Typography>
            </div>
          ) : null}

          <div className={classes.menuItems}>{menu}</div>

          {!nested 
            ? parentNode && (
                <div className={classes.category}>
                  <MenuItem
                    key={parentNode.id}
                    classes={{
                      gutters: classes.menuItemGutters,
                      root: classes.menuItemRoot,
                    }}
                    onClick={(e) => handleClose(parentNode)}
                  >
                    Shop all {parentNode.displayName}
                  </MenuItem>
                </div>
              )
            : null}
        </div>
        {!nested && parentNode.img[0] ? (
            <div className={classes.image}>
                <img
                  src={parentNode.img[0].cdnURL || ''}
                  alt={parentNode?.name || ''}
                />
            </div>
          ) : null}
      </div>
    );
  };

  let parentCatalogId = props.menuJson[0]?.id.toString()
  
  return (
    <div className={classes.menu}>
      {props.menuJson && props.menuJson.length ? (
        <div className={classes.root}>
          {props.menuJson.map((m: MenuNode) => (
            <div key={m.id}>
              {m.parentId === parentCatalogId ? (
                <Button
                  aria-controls={`menu-${m.id}`}
                  onClick={(e: any) => handleClick(e)}
                >
                  {m.displayName}
                </Button>
              ) : null}
              <Menu
                PopoverClasses={{ paper: classes.popoverPaper }}
                classes={{ list: classes.menulist }}
                open={
                  anchorEl !== null &&
                  anchorEl.getAttribute('aria-controls') === `menu-${m.id}`
                }
                className={classes.menulist}
                anchorEl={anchorEl}
                onClose={handleClose}
                keepMounted
                getContentAnchorEl={null}
              >
                {renderMenu(m,false,0)}
              </Menu>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Skeleton
            variant="rect"
            width={120}
            height={30}
            className={classes.skeleton}
          />
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  popoverPaper: {
    zIndex: 1,
    left: '0 !important',
    top: '75px !important',
    width: '100%',
    maxHeight: '600px',
    maxWidth: '100%',
  },
  category: {
    display: 'flex',
    flexDirection:'column',
    width: '50%',
  },
  menuItems: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    maxHeight: '400px',
  },
  menulist: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '30px 15px 30px 15px',
    height: 'auto',
  },
  menuItemRoot: {
    minWidth: '50px',
    transition: 'all 0.1s',
    color: 'rgba(0, 0, 0, 0.7)',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'black',
    },
  },
  nestedMenu: {
    display:'flex',
    flexDirection:'row'
  },
  menuItemGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  full: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    width: '450px',
    margin: 0,
    padding: 0,
  },  
  image: {
    paddingTop: 200,
    width: 200,
    position: 'relative',
    height: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > img': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    },
  },
  menu: {
    //flexGrow: 1,
    display: 'flex',

    '@media screen and (max-width: 768px)': {
      //order: 1,
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
  megamenu: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  skeleton: {
    marginRight: 10,
  },
}));

export default MegaEveMenu;
