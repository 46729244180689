import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ProductCompact from 'components/ProductList/productCompact';

import { useTypeAhead } from 'operations/queries/productTypeAhead';

// #region Material-UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Link from '@material-ui/core/Link';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
// #endregion Material-UI

interface Props {
  toggleSearchMenu(isOpen: boolean): void;
  isOpen: boolean;
  // If set to true the search component will support typeahead
  // It will try to get the list of products from an API
  // based on entered text.
  // Default: false
  enableTypeahead?: boolean;
}

const Search: React.FC<Props> = ({
  isOpen,
  toggleSearchMenu,
  enableTypeahead,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { fetchTypeAhead, data } = useTypeAhead();
  const [open, setOpen] = React.useState(false);
  /* eslint-disable-next-line no-unused-vars */
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any[]>([]);
  const urlParams = new URLSearchParams(location.search);
  const [value, setValue] = useState(
    urlParams.has('keyword') ? urlParams.get('keyword') || '' : ''
  );

  useEffect(() => {
    if (!open) {
      setList([]);
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      setList(data?.productsTypeAhead)
    }
  }, [data])

  const advancedSearch = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    history.push(`/search?keyword=${encodeURIComponent(value)}`);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);

    fetchTypeAhead({
      variables: {
        input: {
          search: event.target.value
        }
      }
    });
  };

  return (
    <>
      {isOpen ? (
        <>
          <Slide in direction="left" mountOnEnter unmountOnExit>
            <Paper
              component="form"
              className={classes.root}
              onSubmit={advancedSearch}
            >
              <Autocomplete
                id="search-products"
                className={classes.autocomplete}
                open={open}
                onOpen={() => {
                  if (enableTypeahead) {
                    setOpen(true);
                  }
                }}
                onClose={() => {
                  if (enableTypeahead) {
                    setOpen(false);
                  }
                }}
                options={list}
                getOptionLabel={(option) => option.name || ''}
                includeInputInList
                loadingText="Loading..."
                loading={open && loading}
                noOptionsText=""
                classes={{
                  popper: classes.popper,
                  option: classes.option,
                }}
                renderInput={(params) => (
                  <InputBase
                    id={params.id}
                    disabled={params.disabled}
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    className={classes.input}
                    fullWidth
                    onChange={handleChange}
                    placeholder="Search Products"
                    endAdornment={
                      <>
                        <div
                          style={{
                            width: '25px',
                          }}
                        >
                          {loading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                        </div>
                      </>
                    }
                  />
                )}
                renderOption={(product) => (
                  <Link
                    key={product.id}
                    href={`/product-detail/${product.seoName}`}
                    className={classes.product}
                    color="textPrimary"
                    underline="none"
                  >
                    <ProductCompact product={product} />
                  </Link>
                )}
              />
              <IconButton
                type="submit"
                aria-label="search"
                onClick={advancedSearch}
              >
                <SearchIcon />
              </IconButton>
              <Divider orientation="vertical" />
              <IconButton
                aria-label="Close"
                edge="end"
                onClick={() => toggleSearchMenu(false)}
              >
                <CloseIcon />
              </IconButton>
            </Paper>
          </Slide>
        </>
      ) : (
        <IconButton color="inherit" onClick={() => toggleSearchMenu(true)}>
          <SearchIcon />
        </IconButton>
      )}
    </>
  );
};

Search.defaultProps = {
  enableTypeahead: false,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2px 10px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      flexGrow: 1,
    },
    autocomplete: {
      flex: 1,
    },
    popper: {
      [theme.breakpoints.down('xs')]: {
        minWidth: '97%',
      },
      [theme.breakpoints.up('sm')]: {
        minWidth: 400,
      },
    },
    option: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:first-child': {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      borderWidth: 0,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 15,
      margin: 4,
    },
    product: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      margin: 0,
    },
  })
);

export default Search;
