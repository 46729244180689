import defaults from 'lodash/defaults';

type currencyDisplay = 'symbol' | 'narrowSymbol' | 'code' | 'name';

interface CurrencyFormatOptions {
  locale?: string;
  currency?: string;
  currencyDisplay?: currencyDisplay;
}
const defaultOptions: CurrencyFormatOptions = {
  locale: 'en-CA',
  currency: 'USD',
};
/* IE 11 and up */
export const formatCurrency = (
  config: CurrencyFormatOptions = defaultOptions,
) => {
  const options = defaults(config, defaults);

  return new Intl.NumberFormat(options.locale, {
    style: 'currency',
    currency: options.currency,
    currencyDisplay: 'symbol',
  });
};
