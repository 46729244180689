import {} from '@material-ui/core';
import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';

import set from 'lodash/set';
import merge from 'lodash/merge';
import { ThemeOverride } from './interfaces/theme';

const themeBuilder = (themeObject?: any, options?: ThemeOverride[]) => {
  const option: ThemeOverride[] = options || [];
  const created = option.reduce((prev, curr) => {
    set(prev, curr.name, curr.value);
    return prev;
  }, {} as any);
  const fontPrimary = `"Atlas Typewriter","Lucida Sans Typewriter","Lucida Console","monaco","Bitstream Vera Sans Mono",monospace`
  const fontSecondary = `"Clarendon Graphic","Baskerville","Palatino","Palatino Linotype","Georgia",Serif`
  const theme: ThemeOptions = {
    breakpoints: {
      values: {
        xs: themeObject.xsBreakpoint,
        sm: themeObject.smBreakpoint,
        md: themeObject.mdBreakpoint,
        lg: themeObject.lgBreakpoint,
        xl: themeObject.xlBreakpoint,
      },
    },
    palette: {
      background: {
        //default: themeObject.background,
        //paper: themeObject.panelBackground,
        default: '#ffffff',
        paper: themeObject.panelBackground,
      },
      primary: {
        //main: themeObject.colorPrimary,
        main: '#00ff00',
        light: '#00ff00',
        dark: '#00ff00',
        contrastText: '#000000'
      },
      secondary: {
        //main: themeObject.colorSecondary,
        main: '#000000',
        light: '#000000',
        dark: '#000000',
        contrastText: '#ffffff'
      },
      text: {
        //primary: themeObject.textPrimary,
        //secondary: themeObject.textSecondary,
        primary: '#000000',
        secondary: '#ffffff',
      },
      warning: {
        //main: themeObject.warning,
        main: themeObject.warning,
      },
      success: {
        //main: themeObject.success,
        main: themeObject.success,
      },
      error: {
        //main: themeObject.error,
        main: themeObject.error,
      },
      info: {
        //main: themeObject.info,
        main: themeObject.info,
      },
    },
    typography: {
      fontFamily: themeObject.fontFamily,
      h1: {
        fontSize: `${themeObject.h1}rem`,
        fontFamily: fontSecondary
      },
      h2: {
        fontSize: `${themeObject.h2}rem`,
        fontFamily: fontSecondary
      },
      h3: {
        fontSize: `${themeObject.h3}rem`,
        fontFamily: fontSecondary
      },
      h4: {
        fontSize: `${themeObject.h4}rem`,
        fontFamily: fontSecondary
      },
      h5: {
        fontSize: `${themeObject.h5}rem`,
        fontFamily: fontSecondary
      },
      h6: {
        fontSize: `${themeObject.h6}rem`,
        fontFamily: fontSecondary
      },
      body1: {
        fontSize: `${themeObject.body1}rem`,
        fontFamily: fontPrimary
      },
      body2: {
        fontSize: `${themeObject.body2}rem`,
        fontFamily: fontPrimary
      },
      button: {
        fontFamily: fontPrimary
      },
    },
    shape: {
      borderRadius: 0,
    },
  };

  // Default theme needed in case the generate theme has undefined values.
  const defaultTheme = createMuiTheme();
  const mergedTheme = merge(defaultTheme, theme || {}, created);
  return createMuiTheme(mergedTheme);
};

export default themeBuilder;
