import { gql, useMutation } from '@apollo/client';
import { GET_CART, GET_WISH_LIST } from 'operations/queries';
import { FETCH_APP_DATA } from 'operations/queries/getAppData';
import { GET_LINKED_ACCOUNTS } from 'operations/queries/getLinkedAccounts';
import { FETCH_SESSION } from 'operations/queries/getSession';

export const ENTER_SOBO_MODE = gql`
  mutation setSoboMode($id: ID!) {
    setSoboMode(id: $id) {
      contactId
    }
  }
`;

export const EXIT_SOBO_MODE = gql`
  mutation exitSoboMode {
    exitSoboMode
  }
`;

export const enterSoboMode = () => {
  const [mutate, { data, error }] = useMutation<boolean, { id: string }>(
    ENTER_SOBO_MODE,
    {
      refetchQueries: [
        {
          query: FETCH_SESSION,
        },
        {
          query: FETCH_APP_DATA,
        },
        {
          query: GET_CART,
        },
        {
          query:GET_WISH_LIST
        }
      ],
      awaitRefetchQueries: true,
    }
  );

  return { mutate, data, error };
};

export const exitSoboMode = () => {
  const [mutate, { data, error }] = useMutation<boolean, null>(EXIT_SOBO_MODE, {
    refetchQueries: [
      {
        query: FETCH_SESSION,
      },
      {
        query: FETCH_APP_DATA,
      },
      {
        query: GET_CART,
      },
      {
        query: GET_LINKED_ACCOUNTS
      },{
        query: GET_WISH_LIST
      }
    ],
    awaitRefetchQueries: true,
  });

  return { mutate, data, error };
};
