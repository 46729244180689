import { gql, useLazyQuery } from '@apollo/client';

export const TYPEAHEAD = gql`
  query TYPEAHEAD($input: ProductTypeaheadInput) {
    productsTypeAhead(input: $input) {
      id
      name
      seoName
    }
  }
`;



export const useTypeAhead = (query?: string) => {
    const [ fetchTypeAhead, {loading, data}] = useLazyQuery<any, any>(TYPEAHEAD, {
        variables: {
            input: {
                search: query
            }
        }
    });

    return {fetchTypeAhead, loading, data};
}