import React from 'react';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

interface Props {
  className?: string;
  icon?: 'search' | 'cart';
}

const NoResults: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${props.className || ''}`}>
      <Container className={classes.container}>
        <Container className={classes.iconContainer}>
          {!props.icon || props.icon === 'search' ? (
            <SearchIcon color="primary" className={classes.icon} />
          ) : null}
          {props.icon === 'cart' ? (
            <ShoppingCartIcon color="primary" className={classes.icon} />
          ) : null}
        </Container>
        <Container className={classes.contentContainer}>
          <Typography variant="h5" className={classes.heading}>
            No Results
          </Typography>
          <p className={classes.message}>{props.children}</p>
        </Container>
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  container: {
    display: 'grid',
    gridTemplateAreas: "'icon content'",
    gridTemplateColumns: 'auto fit-content',
    alignItems: 'center',
    width: 'fit-content',
    flexDirection: 'column',
  },
  heading: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
  icon: {
    fontSize: '75px',
    marginTop: '-17px',
  },
  contentContainer: {
    gridArea: 'content',
    paddingLeft: '0',
  },
  iconContainer: {
    gridArea: 'icon',
    display: 'flex',
    alignItems: 'flex-start',
    paddingRight: theme.spacing(2),
  },
  message: {
    marginTop: '0',
  },
}));

export default NoResults;
