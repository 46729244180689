import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Currency from 'components/Currency';
// Icons
import DeleteIcon from '@material-ui/icons/Delete';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import {
  CartLinesFragment,
  CartLinesFragment_img,
  CartLinesFragment_selectedDimensions
} from 'operations/fragments/__generated__/CartLinesFragment';
import AddItemToList from 'components/MyList/addItemToList';
import { GET_WISHLIST_LIST } from 'operations/queries/__generated__/GET_WISHLIST_LIST';

interface Dimension {
  id: string;
  name: string | null;
  value: string | null;
}
interface CartLine {
  ProductId: string;
  quantity: number;
  amount: number;
  Code: string;
  name: string;
  listPrice: number;
  img: Array<{
    id: string;
    alt: string;
    cdnURL: string;
  }>;
  selectedDimensions: CartLinesFragment_selectedDimensions[];
}
interface Props {
  className?: string;
  cartLine: CartLinesFragment;
  onDelete: (cartLine: CartLine) => void;
  setState: Function;
  wishData?:GET_WISHLIST_LIST;
  wishLoading?:boolean;
  wishError?:Error;
}

const ProductMiniCartItem: React.FC<Props> = (props) => {
  const classes = useStyles();


  const handleClick = () => {
    props.onDelete(props.cartLine as any);
  };



  return (
    <div className={`${classes.root} ${props.className || ''}`}>
      <Link
        component={RouterLink}
        to={`/product-detail/${props.cartLine.code}`}
        onClick={() => {
          props.setState({ miniCartOpen: false });
        }}
      >
        <div className={classes.image}>
          <img
            src={props.cartLine.img ? props.cartLine?.img[0]?.cdnURL : ''}
            alt={`${props.cartLine.name}image`}
            onError={(event) => {
              event.currentTarget.src = '/no-image-40.png';
            }}
          />
        </div>
      </Link>
      <div className={classes.details}>
        <Link
          component={RouterLink}
          to={`/product-detail/${props.cartLine.code}`}
          color="inherit"
          onClick={() => {
            props.setState({ miniCartOpen: false });
          }}
        >
          <Typography variant="h6" gutterBottom>
            {props.cartLine.name}
          </Typography>
        </Link>
        <div className={classes.qtyPrice}>
          <div className={classes.label}>Qty:</div>
          <Typography
            variant="body2"
            color="secondary"
            className={classes.price}
          >
            {props.cartLine.quantity}
          </Typography>
          <div style={{ margin: '0px 8px' }}>x</div>
          <div className={classes.label}>Price:</div>
          <Typography
            variant="body2"
            color="secondary"
            className={classes.price}
          >
            <Currency value={props.cartLine.listPrice} />
          </Typography>
        </div>
        <div>
          {props.cartLine.selectedDimensions
            ? props.cartLine?.selectedDimensions?.map((dimension:any) => (
                <Typography key={dimension.id} variant="body2" component="p">
                  <b>{dimension.name || null}: </b>
                  {dimension.value || null}
                </Typography>
              ))
            : null}
        </div>
        <div>
          <IconButton size="small" onClick={handleClick}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
        <AddItemToList wishlistLists={props.wishData} loading={props.wishLoading} error={props.wishError} productId={props.cartLine.productId}/>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  details: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    paddingTop: 80,
    width: 120,
    position: 'relative',
    height: 0,
    marginRight: theme.spacing(1),
    '& > img': {
      position: 'absolute',
      top: 0,
      left: 0,
      //height: '100%',
      width: '100%',
    },
  },
  price: {
    fontWeight: 'bold',
  },
  stretch: {
    flexGrow: 1,
  },
  qtyPrice: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginRight: theme.spacing(1),
    fontWeight: 600,
  },
}));

export default ProductMiniCartItem;
