import { gql, useQuery } from '@apollo/client';
import _get from 'lodash/get';


export const INITIAL_LOAD = gql`
	query initialLoad {
		user:me {
			id
			account {
				id
				name
			}
			code
			email
			contact {
				id
				fullName,
				firstName,
				lastName
			}
			claims {
				id
				actionId
				action
				subjectId
				subject
				isCan
				isPassThrough
			}
		}
		settings {
			resultsPerPage
			currency
			siteName
			logoPath
			themeName
			taxesOnShipping
			products {
				resultsPerPage
			}
			customers {
				resultsPerPage
			}
			selectedTheme {
				themeId
				colorPrimary
				colorSecondary
				textPrimary
				textSecondary
				error
				warning
				info
				success
				background
				panelBackground
				h1
				h2
				h3
				h4
				h5
				h6
				body1
				body2
				h1Font
				h2Font
				h3Font
				h4Font
				h5Font
				h6Font
				body1Font
				body2Font
				xsBreakpoint
				smBreakpoint
				mdBreakpoint
				lgBreakpoint
				xlBreakpoint
				defaultTheme
				themeName
				fontFamily
			}
		},
	}
`;

export const useInitialLoad = () => {

	const { data, loading, error } = useQuery(INITIAL_LOAD);

	const site = {
		settings: _get(data, "settings", {}),
		session: {
			isLoggedIn: !!(data?.user),
			user: _get(data, "user", {}) || {},
			sobo: _get(data, "sobo", {}) || {},
			cart: _get(data, "cart", {}) || {},
		}
	};

	return { site, loading, error };
};
