import { gql, useMutation } from '@apollo/client';
import * as refreshTokenTypes from './__generated__/refreshToken';

export const REFRESH_TOKEN = gql`
  mutation refreshToken($accessToken: String!) {
    refreshToken(accessToken: $accessToken) {
      refreshToken
      accessToken {
        token
      }
    }
  }
`;

export const useRefreshToken = () => {
  const [mutate, { data, error }] = useMutation<
    refreshTokenTypes.refreshToken,
    refreshTokenTypes.refreshTokenVariables
  >(REFRESH_TOKEN);

  return { mutate, data, error };
};
