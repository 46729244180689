/* tslint:enable:no-any */
/**
 *
 * ========================================
 * *********** IMPORTANT ******************
 * ========================================
 * Supports Shop On Behalf Of
 * This functionality is outdated and required modification
 * It was developed for the B2B site (PRS) using Redux and Saga
 * Since then we moved to GraphQL / Apollo.
 * The component must be updated in order to work with new data access
 */
import React, {useEffect, useReducer} from 'react';
import clsx from 'clsx';

import {useHistory} from 'react-router-dom';

// #region Material-UI
import {Hidden, IconButton, makeStyles, Theme} from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import CloseIcon from '@material-ui/icons/Close';
import {Pagination} from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
// #endregion Material-UI
import {FETCH_SESSION, SessionData} from '../../operations/queries/getSession';
import {useMutation, useLazyQuery, gql, useQuery} from '@apollo/client';
import DialogPrompt from '../DialogPrompt';
import {AccountCard} from '../AccountCard';
import SoboAccountListItem from './SoboAccountListItem';
import KeywordSearch from '../KeywordSearch';
import {GET_LINKED_ACCOUNTS, GetLinkedAccountsData, LinkedAccount} from "../../operations/queries/getLinkedAccounts";
import {SoboActionEnum} from "./soboInterfaces";
import { soboReducer, initialState} from "./reducer"
import { enterSoboMode,exitSoboMode } from 'operations/mutations/setSoboMode';


/**
 * Handles displaying sobo list and controlling sobo functionality
 * @param props
 * @constructor
 */
const SoboDrawer: React.FC = () => {
    const PAGE_SIZE = 10;
    const classes = useStyles();

    //TODO: Update to generated types  e4cs-312
    const [loadSoboList, {
        called, data, error,
    }] = useLazyQuery<any>(
        GET_LINKED_ACCOUNTS
    );

    const {data: sessionData} = useQuery<any>(FETCH_SESSION)
    const { mutate: enterSobo } = enterSoboMode();
    const { mutate: exitSobo } = exitSoboMode();

    const [state, dispatch] = useReducer(soboReducer, initialState);
    const history = useHistory();
    let soboListSelector: LinkedAccount[] = [];

    if (called && data) {
        soboListSelector = data?.soboAccountList?.nodes.slice().sort((item:any,item2:any) => item.hasSoboCart - (item2.hasSoboCart ? item2.hasSoboCart : false)).reverse() || [];

    }
    

    useEffect(() => {
        if (Number(sessionData?.session.isImpersonated)) {
            const sessionUser = sessionData?.session.soboUser;
            if (sessionUser) {
                
                //TODO: After confirmation, update to generated types  e4cs-312

                const acc: any = {
                    name: `${sessionUser.firstName} ${sessionUser.lastName}`,
                    code: sessionUser.accountId.toString(),
                    email: sessionUser.email,
                    id: sessionUser.accountId.toString(),
                    userId: sessionUser.aspNetUserId,
                    phoneNumber: null
                }
                dispatch({type: SoboActionEnum.setSelectedAccount, payload: acc})
            }

        }
    }, [sessionData])

    const setPage = (page: number): void => {
        dispatch({type: SoboActionEnum.setPage, payload: page});
    };

    const setDrawerOpen = (isOpen: boolean): void => {
        dispatch({type: SoboActionEnum.setDrawerOpen, payload: isOpen});
    };

    useEffect(() => {
        if (state.drawerOpen && !state.selectedSoboAccount) {
            loadSoboList();
        }
        setPage(1);
    }, [state.drawerOpen, loadSoboList, state.selectedSoboAccount]);

    useEffect(() => {
        if (data) {
            loadSoboList({
                variables: {
                    skip: (state.page - 1) * PAGE_SIZE,
                    search: state.keyword
                }
            })
        }
    }, [state.page, state.keyword])

    /**
     * dispatches the keyword search to state
     * @param keyword Keyword from the KeywordSearch component to filter account list
     */
    const handleKeywordDispatch = (keyword: string): void => {
        dispatch({type: SoboActionEnum.changeKeyword, payload: keyword});
    };

    /**
     * Toggles whether the drawer is open / closed
     */
    const toggleDrawer = (): void => setDrawerOpen(!state.drawerOpen);

    /**
     * Gets total number of pages based on page size and length of the list
     */
    const getTotalPages = (): number => Math.ceil((data?.soboAccountList?.pageInfo?.resultsReturned || 1) / PAGE_SIZE);

    /**
     * Renders a placeholder skeleton while the api loads data
     */
    const renderSkeleton = (): JSX.Element => (
        <>
            {[...Array(PAGE_SIZE).keys()].map((key , i: number) => (
                <Skeleton
                    key={i}
                    className={classes.skeleton}
                    variant="rect"
                    width="100%"
                    height="60px"
                />
            ))}
        </>
    );

    /**
     * Changes the page
     * @param event not used
     * @param newPage Page to set local state to
     */
    const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number): void => setPage(newPage);

    /**
     * Sets sobo mode when user clicks on select button on contact card
     * dispatches event to store
     * @param contact contact for sobo mode
     */
    const handleSetSobo = (contact: LinkedAccount): void => {
        // dispatch(setSoboMode(contact.AccountId));
        enterSobo({
            variables: {
                id: contact.id,
            },
        });
        history.push('/account');
    };

    /**
     * Shows dialog prompt to confirm exit sobo
     */
    const handleExitSoboRequest = (): void => {
        dispatch({
            type: SoboActionEnum.exitSobo,
            payload: {
                drawerOpen: false,
                showExitDialog: true,
            },
        });
    };

    /**
     * Dispatches event to exit sobo after user clicks confirm
     */
    const handleExitSoboConfirm = (): void => {
      if (state.selectedSoboAccount) {
        exitSobo()
          .then(() => dispatch({ type: SoboActionEnum.exitSoboConfirm }))
          .then(() => history.push('/account'));
      }
    };

    /**
     * Hides confirmation dialog
     */
    const handleExitSoboCancel = (): void => {
        dispatch({type: SoboActionEnum.exitSoboCancel})
    };

    const getShowingStart = (): number => {
        const currentlyShowing = (state.page - 1) * PAGE_SIZE + PAGE_SIZE;

        return data?.soboAccountList?.pageInfo?.resultsReturned || PAGE_SIZE  >= 0
            ? soboListSelector.length
            : currentlyShowing > soboListSelector.length
                ? soboListSelector.length
                : currentlyShowing;

    };

    /**
     * This function is called and rendered when sobo mode is inactive
     */
    const renderSoboList = (): JSX.Element => (
        <>
            <KeywordSearch dispatchKeyword={handleKeywordDispatch}/>
            <p>
                Showing
                {' '}
                {getShowingStart()}
                {' '}
                of
                {' '}
                {data?.soboAccountList?.pageInfo?.resultsReturned || 0}
            </p>
            <List>
                {soboListSelector && soboListSelector.length
                    ? soboListSelector
                        .map((contact: LinkedAccount) => (
                            <SoboAccountListItem
                                key={contact.id}
                                contact={contact}
                                handleSetSobo={handleSetSobo}
                            />
                        ))
                    : renderSkeleton()}
            </List>
            {getTotalPages() > 1 && (
                <Pagination
                    count={getTotalPages()}
                    page={state.page}
                    onChange={handlePageChange}
                    className={classes.pagination}
                    classes={{
                        ul: classes.paginationUl,
                    }}
                />
            )}
        </>
    );

    if (error) {
        return (
            <strong>
                Error loading sobo list
                {error.message}
            </strong>
        );
    }
    return (
        <>
            <div className={classes.soboElementWrapper}>
                <div
                    onClick={toggleDrawer}
                    className={clsx({
                        [classes.soboActiveClass]: Number(sessionData?.session?.isImpersonated),
                        [classes.soboIcon]: true,
                    })}
                >

                    {Number(sessionData?.session?.isImpersonated) ? <GroupIcon/> : <GroupAddIcon/>}
                </div>
                <Hidden mdDown>
                    {Number(sessionData?.session?.isImpersonated) ? <Typography>
                        Shopping as {state?.selectedSoboAccount?.name}
                    </Typography> : null}
                </Hidden>

            </div>
            <SwipeableDrawer
                anchor="right"
                open={state.drawerOpen}
                onOpen={toggleDrawer}
                onClose={toggleDrawer}
                className={classes.menuDrawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Typography variant="h6" className={classes.headerText}>
                    {state.selectedSoboAccount ? `Shopping On Behalf Of ${state?.selectedSoboAccount?.name}` : 'Select Dealer'}
                </Typography>
                <IconButton
                    aria-label="Drawer Close"
                    onClick={toggleDrawer}
                    children={<CloseIcon/>}
                    size="medium"
                    className={classes.closeIcon}
                />

                {state.selectedSoboAccount ? (
                    <AccountCard
                        contact={state.selectedSoboAccount}
                        cardActionText="Exit Sobo"
                        cardSecondaryActionText={"do stuff"}
                        handleClick={handleExitSoboRequest}
                        handleActionClick={handleExitSoboRequest}
                        shouldShowPrimaryAction={true}
                    />
                ) : (
                    renderSoboList()
                )}
            </SwipeableDrawer>
            <DialogPrompt
                dialogOpen={state.showExitDialog}
                dialogTitle="Exit SOBO"
                dialogContent="Are you sure you want to exit SOBO?"
                onDialogConfirm={handleExitSoboConfirm}
                onDialogCancel={handleExitSoboCancel}
                dialogConfirmText="Exit SOBO"
            />
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    menuDrawer: {
        justifyContent: 'center',
    },
    drawerPaper: {
        minWidth: '500px',
        padding: '1rem',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: 0,
        },
    },
    headerText: {
        textAlign: 'left',
        marginBottom: '2rem',
    },
    pagination: {
        marginTop: 'auto',
        marginBottom: '1rem',
    },
    paginationUl: {
        justifyContent: 'center',
    },
    closeIcon: {
        position: 'absolute',
        top: '5px',
        right: 0,
        width: '50px',
    },
    skeleton: {
        marginTop: '1rem',
    },
    customBadgeColor: {
        backgroundColor: 'lightgreen',
    },
    cardActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    cardHeader: {
        display: 'flex',
        alignItems: 'center',
    },
    accountIcon: {
        marginRight: '1rem',
    },
    soboIcon: {
        cursor: 'pointer',
        margin: theme.spacing(0.5),
        padding: theme.spacing(1, 2),
        boxSizing: 'content-box',
    },
    soboActiveClass: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.success.main,
    },
    soboElementWrapper: {
        display: 'flex',
        alignItems: 'center'
    }
}));

export default SoboDrawer;
