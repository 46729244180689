import { gql, InMemoryCache, ReactiveVar, makeVar } from '@apollo/client';
import { SHIPPING_BY_CARRIERS } from 'operations';
import { AddToCartMessage, AttentionMessage } from 'interfaces/messages';
import { getAuthToken, getUserFromToken } from 'utils/auth';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Cart: {
      fields: {
        selectedShipping: {
          read(curr, args) {
            try {
              const carriers: any = args.cache.readQuery({
                query: SHIPPING_BY_CARRIERS,
                variables: {
                  id: args.variables?.id,
                },
              });
              const cart: any = args.cache.readQuery({
                query: gql`
                  query CART_IN {
                    cart {
                      id
                      shippingMethodId
                    }
                  }
                `,
              });
              const shippingMethodId = cart?.cart?.shippingMethodId;

              if (
                shippingMethodId &&
                Array.isArray(carriers?.shippingbyCarrier)
              ) {
                const selected = carriers?.shippingbyCarrier.find(
                  (method: any) => method.id === shippingMethodId
                );

                if (selected) {
                  return selected;
                }
                return null;
              }
            } catch (error) {
              console.log('cache not populated yet.');
              return null;
            }

            return null;
          },
        },
      },
    },
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return !!getAuthToken();
          },
        },
        user: {
          read() {
            const token = getAuthToken();
            return getUserFromToken(token);
          },
        },
        lowAttentionMessages: {
          read() {
            return lowAttentionMessagesVar();
          },
        },
        mediumAttentionMessages: {
          read() {
            return mediumAttentionMessagesVar();
          },
        },
        highAttentionMessages: {
          read() {
            return highAttentionMessagesVar();
          },
        },
        addToCartMessages: {
          read() {
            return addToCartMessagesVar();
          },
        },
      },
    },
  },
});

export const lowAttentionMessagesVar: ReactiveVar<AttentionMessage[]> = makeVar<
  AttentionMessage[]
>([]);

export const mediumAttentionMessagesVar: ReactiveVar<
  AttentionMessage[]
> = makeVar<AttentionMessage[]>([]);

export const highAttentionMessagesVar: ReactiveVar<
  AttentionMessage[]
> = makeVar<AttentionMessage[]>([]);

export const addToCartMessagesVar: ReactiveVar<AddToCartMessage[]> = makeVar<
  AddToCartMessage[]
>([]);
