import React from 'react';
import { Product } from 'interfaces/product';
import get from 'lodash/get';

// import { formatCurrency } from 'utils/format-currency';
import Currency from 'components/Currency';
import { addDefaultImage } from 'utils/product-helpers';
// Material-UI
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

interface Props {
  product: any;
}

const ProductCompact: React.FC<Props> = (props) => {
  const classes = useStyles();

  const getProductImage = (product: Product): string => get(product, 'product.Image[0].ThumbnailImage', '');

  return (
    <div className={classes.product}>
      <img
        className={classes.image}
        src={getProductImage(props.product)}
        onError={(e) => addDefaultImage(e)}
        alt={props.product.name}
      />
      <div className={classes.productDetails}>
        <div
          className={classes.name}
          dangerouslySetInnerHTML={{
            __html: props.product.HighlightedName || props.product.name,
          }}
        />
        <div className={classes.price}>
          <Currency value={props.product.price} />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  product: {
    display: 'flex',
    flex: 1,
    margin: 0,
  },
  productDetails: {
    flex: 1,
    padding: theme.spacing(1, 2, 1, 2),
  },
  name: {
    fontWeight: 600,
    fontSize: '14px',
    '& > span': {
      background: '#FFFF8F',
    },
  },
  price: {
    color: theme.palette.grey[700],
    fontSize: '13px',
  },
  image: {
    height: 60,
    width: 60,
    objectFit: 'contain',
    objectPosition: 'center center',
  },
}));

export default ProductCompact;
