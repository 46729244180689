import React from 'react';
import { Dialog } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

interface Props {
  dialogOpen: boolean;
  dialogTitle: string;
  dialogContent: string;
  onDialogConfirm: () => void;
  onDialogCancel: () => void;
  dialogCancelText?: string;
  dialogConfirmText?: string;
}

/**
 * Generic component for confirmation prompting
 * @param props functions and text to display
 * @constructor
 */
const DialogPrompt: React.FC<Props> = (props: Props) => (
  <Dialog
    open={props.dialogOpen}
    onClose={props.onDialogCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" component="p">
        {props.dialogContent}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onDialogCancel} color="primary">
        {props.dialogCancelText || 'No'}
      </Button>
      <Button onClick={props.onDialogConfirm} color="primary" autoFocus>
        {props.dialogConfirmText || 'Yes'}
      </Button>
    </DialogActions>
  </Dialog>
);

export default DialogPrompt;
