import React, { useReducer, useContext } from 'react';
import { messageMutations } from 'operations/mutations/messages';
type Action = { type: 'showMobileMenu' } | { type: 'hideMobileMenu' } ;
type Dispatch = (action: Action) => void;
type State = { isMobileMenuOpen: boolean };
type NavigationProviderProps = { children: React.ReactNode };

const NavigationStateContext = React.createContext<State | undefined>(
  undefined,
);
const NavigationDispatchContext = React.createContext<Dispatch | undefined>(
  undefined,
);

function navigationReducer(state: State, action: Action) {
  switch (action.type) {
    case 'showMobileMenu': {
      return { isMobileMenuOpen: true };
    }
    case 'hideMobileMenu': {
      return { isMobileMenuOpen: false };
    }

    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function NavigationProvider({ children }: NavigationProviderProps) {
  const [state, dispatch] = useReducer(navigationReducer, {
    isMobileMenuOpen: false,
  });

  return (
    <NavigationStateContext.Provider value={state}>
      <NavigationDispatchContext.Provider value={dispatch}>
        {children}
      </NavigationDispatchContext.Provider>
    </NavigationStateContext.Provider>
  );
}

function useNavigationState(): State {
  const context = useContext(NavigationStateContext);
  if (context === undefined) {
    throw new Error(
      'useNavigationState must be used within a NavigationProvider',
    );
  }
  return context;
}

function useNavigationDispatch() {
  const context = useContext(NavigationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useNavigationDispatch must be used within a NavigationProvider',
    );
  }
  return context;
}

export { NavigationProvider, useNavigationState, useNavigationDispatch };
