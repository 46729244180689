import { gql, useMutation } from '@apollo/client';
import { cartLinesFragment } from '../fragments/cartLines';

// Utils

// TODO: Convert selections to qgl fragment as we are using it also in queries/getCart
export const DELETE_CART_LINE = gql`
  mutation deleteCartLine($input: DeleteCartLineInput!) {
    deleteCartLine(input: $input) {
      id
      itemCount
      lines {
        ...CartLinesFragment
      }
      subtotal
      discountTotal
      freightTotal
      taxTotal
      total
    }
  }
  ${cartLinesFragment}
`;

export const useDeleteCartLine = () => {
  const [mutate, { data, loading, error }] = useMutation(DELETE_CART_LINE, {
    update(cache, { data }) {},
  });

  const customMutate = (productId: string, unitOfMeasureId: string) => {
    return new Promise((resolve, reject) => {
      const variables = {
        input: {
          productId,
          unitOfMeasureId,
        },
      };
      mutate({ variables })
        .then((response: any) => {})
        .catch(reject);
    });
  };
  return { mutate: customMutate, data, loading, error };
};
