import React from 'react';
import { useTranslation } from 'react-i18next';
// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

interface Props {}

const Copyright: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <img src="https://www.norco.com/_application/views/images/branding/norco-wordmark-white.svg" className={classes.imageLogo}></img>
      <Typography variant="caption" className={classes.text}>RIDER-FIRST
MTN BIKES</Typography>
      <Typography variant="caption" className={classes.text}>DESIGNED AND TESTED
IN THE BACKWOODS
OF BRITISH COLUMBIA</Typography>
      <img src="https://www.norco.com/_application/views/images/branding/norco-N.svg" className={classes.imageIcon}></img>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    color: theme.palette.text.hint,
    //textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    background: theme.palette.secondary.main
  },
  imageLogo: {
    height: '20px'
  },
  imageIcon: {
    height: '40px'
  },
  text: {
    color: theme.palette.text.secondary
  }
}));

export default Copyright;
