import {gql, useMutation} from "@apollo/client";
import { GET_WISH_LIST } from "operations/queries";


const ADD_TO_WISHLIST = gql`
        mutation addToWishlist($productId:ID! , $wishlistId:ID!) {
            addItemToWishlist(productId: $productId , wishlistId: $wishlistId) {
                id
            }
        }
    `
export const useAddToWishlist = () => {
    const [mutate, {data, error}] = useMutation<any>(ADD_TO_WISHLIST,{refetchQueries:[{query:GET_WISH_LIST}]});

    return {mutate, data, error}
}
