import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { useGetCatalog } from 'operations/queries/getCategories';
import { useNavigationState } from './navigationContext';

import EveMenu from './EveMenu';
import MobileMenu from './MobileMenu';
import MegaEveMenu from './MegaEveMenu';

interface Props {}

const Navigation = (props: Props) => {
  const { data: catalog, loading, error } = useGetCatalog();
  const navigationState = useNavigationState();
  const theme = useTheme();
  const isLargeViewport = useMediaQuery(theme.breakpoints.up('sm'));

  if (loading) {
    return null;
  }
  if (!catalog) {
    return null;
  }

  return (
    <>
      {isLargeViewport ? (

        <MegaEveMenu
          menuJson={
            catalog
          }
        />
      ) : (
        <MobileMenu
          menuJson={catalog}
          isOpen={navigationState.isMobileMenuOpen}
        />
      )}
    </>
  );
};

export default Navigation;
export {
  NavigationProvider,
  useNavigationDispatch,
  useNavigationState,
} from './navigationContext';
export { MobileMenuToggle } from './MobileMenu';
