import { ApolloClient, ApolloLink, HttpLink, Operation, Observable, NextLink, FetchResult } from '@apollo/client';
import { cache } from 'Cache';
import { resolvers, typeDefs } from 'resolvers';
import { getAuthToken, clearToken, setAuthInfo } from 'utils/auth';
import { isTokenValid } from 'services/auth.service';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';


interface SubscriberInterface {
	next?: (result: FetchResult) => void;
	error?: (error: Error) => void;
	complete?: () => void;
}

interface QueuedRequest {
	operation: Operation;
	forward?: NextLink;
	subscriber?: SubscriberInterface;
	observable?: Observable<FetchResult>;
	next?: (result: FetchResult) => void;
	error?: (error: Error) => void;
	complete?: () => void;
}

class OperationQueuing {
	public queuedRequests: QueuedRequest[] = [];

	constructor() {
		this.queuedRequests = [];
	}

	public enqueueRequest(request: QueuedRequest): Observable<FetchResult> {
		const requestCopy = { ...request };

		requestCopy.observable = requestCopy.observable || new Observable<FetchResult>((observer) => {
			this.queuedRequests.push(requestCopy);

			if (typeof requestCopy.subscriber === 'undefined')
				requestCopy.subscriber = {};

			requestCopy.subscriber.next =
			requestCopy.next || observer.next.bind(observer);
			requestCopy.subscriber.error =
			requestCopy.error || observer.error.bind(observer);
			requestCopy.subscriber.complete =
			requestCopy.complete || observer.complete.bind(observer);
		});

		return requestCopy.observable;
	}

	public consumeQueue(): void {
		this.queuedRequests.forEach((request) => {
			// @ts-ignore
			request.forward(request.operation).subscribe(request.subscriber);
		});
		this.queuedRequests = [];
	}
}

class JwtRefreshLink extends ApolloLink {
	private fetching: boolean;

	private queue: OperationQueuing;
  
	constructor() {
		super();
		this.fetching = false;
		this.queue = new OperationQueuing();
	}

	isTokenValidOrUndefined() {
		const token = getAuthToken();

		if (!token)
			return true;

		const isValid = isTokenValid(token);

		if (isValid)
			return true;
		return false;
	}
  
	request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
		if (typeof forward !== 'function') {
			throw new Error(
				'[JWT Refresh Link]: JWT Refresh Link is a non terminating link and should not be the last in the composed chain'
			);
		}

		// If token does not exist, this could mean that this is not authenticated user,
		// Or the token is not expired - work as normal
		if (this.isTokenValidOrUndefined()) {
			return forward(operation);
		}
  
		if (!this.fetching) {
			this.fetching = true;

			const url = new URL(`${process.env.REACT_APP_API_URL}/graphql`);

			const body = {
				operationName: 'refreshToken',
				variables: {
					accessToken: getAuthToken(),
				},
				query: 'mutation refreshToken($accessToken: String!) {\n refreshToken(accessToken: $accessToken) {aspNetUserId\n refreshToken\naccessToken {token\n expiresIn\n}\n}\n}',
			};

			fetch(url.toString(), {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(body),
				credentials: 'include',
			})
			.then((res) => res.json())
			.then((json) => {
				setAuthInfo(json.data.refreshToken);
			})
			.catch((error) => {
				// If we encounter error with token refresh
				// We need to log user out. There is no way token can be restored.
				cache.reset();
				clearToken();
			})
			.finally(() => {
				this.fetching = false;
	
				this.queue.consumeQueue();
			});
		}
		return this.queue.enqueueRequest({ operation, forward });
	}
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
	if (graphQLErrors) {
		// eslint-disable-next-line
		graphQLErrors.map(({ message, locations, path, extensions }) => {
			switch (extensions && extensions.code) {
				case 'UNAUTHENTICATED': {
					// TODO: Try token refresh here
				}
			}
			console.log(`[GraphQL error]: ${message}, Location: ${locations}, Path: ${path}`);
		});
	}

	if (networkError) {
		console.log(`[Network Error]: ${networkError}`);
	}
});
  
const httpLink = new HttpLink({
	uri: `${process.env.REACT_APP_API_URL}/graphql`,
	credentials: 'include',
	headers: {
		'client-name': 'storefront',
		'client-version': '1.0.0',
	},
});
  
const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = getAuthToken();

	// return the headers to the context so httpLink can read them
	return {
		headers: {
		...headers,
		authorization: token ? `Bearer ${token}` : '',
		},
	};
});


const link = ApolloLink.from([
	new JwtRefreshLink(),
	authLink,
	errorLink,
	httpLink,
]);


const client = new ApolloClient({ cache, link, typeDefs, resolvers });


export { client };

