class EveLogger {
  logger: any;

  constructor(loggingService: any) {
    this.logger = loggingService;
  }

  log(...args: any) {
    this.logger.log(...args);
  }

  info(...args: any) {
    this.logger.info(...args);
  }

  warn(...args: any) {
    this.logger.warn(...args);
  }

  error(...args: any) {
    this.logger.error(...args);
  }

  debug(...args: any) {
    this.logger.debug(...args);
  }
}

class ConsoleLogService {
  timestamp: boolean;

  constructor(options: any = {}) {
    this.timestamp = true; // options.timestamp
  }

  static log(...args: any) {
    const date = `[${new Date().toUTCString()}]`;
    // eslint-disable-next-line no-console
    console.log(date, ...args);
  }

  static info(...args: any) {
    const date = `[${new Date().toUTCString()}]`;
    // eslint-disable-next-line no-console
    console.info(date, ...args);
  }

  static warn(...args: any) {
    // eslint-disable-next-line no-console
    console.warn(...args);
  }

  static error(...args: any) {
    // eslint-disable-next-line no-console
    console.error(...args);
  }

  static debug(...args: any) {
    // eslint-disable-next-line no-console
    console.debug(...args);
  }
}

export const logger = new EveLogger(ConsoleLogService);
