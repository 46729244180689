import React, { useState, useEffect } from 'react';
import isFunction from 'lodash/isFunction';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// src
import { useGetWishListList } from 'operations/queries/getWishListList';
import { useAddToWishlist } from 'operations/mutations/addToWishList';
import {
  Grid,
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { messageMutations } from 'operations/mutations/messages';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import { GET_WISHLIST_LIST_wishlistList } from 'operations/queries/__generated__/GET_WISHLIST_LIST';
import transitions from '@material-ui/core/styles/transitions';

// #region Material-UI

// #endregion Material-UI

//TODO: component props are of any type as passing product items from
//      PRODUCT DETAILS and PRODUCT LIST are of different types.
//      Product detail are NOT updated to the generated types (Needs to fix)

const AddItemToList: React.FC<any> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { mutate: useAddToWishlists } = useAddToWishlist();
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);

  const anchorRef = React.useRef(null);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [isProductInWishList, setProductInWishlist] = React.useState<boolean>(
    false
  );

  // Finds the default wishlist and makes sure its placed in the front of button group
  let orderedList;
  let defaultList;
  let wishLists: any = [];
  
  if (props.wishlistLists) {
    orderedList = props.wishlistLists
      ? props.wishlistLists.wishlistList
      : undefined;
    defaultList = orderedList?.edges.find((edge: any) => edge.node.isDefault);
    wishLists = orderedList?.edges.filter((edge: any) => !edge.node.isDefault);
  }

  if (defaultList) {
    wishLists.unshift(defaultList);
  }

  // Finds if the product is in current selected wishlist
  useEffect(() => {
    if (props.wishlistLists) {
      if (
        (props.productId,
        wishLists[selectedIndex]?.node.products.edges.find(
          (item: any) => item.node.id === props.productId
        ))
      ) {
        setProductInWishlist(true);
      } else {
        setProductInWishlist(false);
      }
    }
  }, [selectedIndex, props.productId]);

  const handleMenuItemClick = (event: any, index: any) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    if (!open) {
      setTimeout(handleOpen, 1);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: any) => {
    setOpen(false);
  };

  const handleAddToWishList = () => {
    useAddToWishlists({
      variables: {
        productId: props.productId,
        wishlistId:
          wishLists && wishLists[selectedIndex]
            ? wishLists[selectedIndex].node.id
            : 1,
      },
    })
      .then(() => {
        messageMutations.addLowAttentionMessage(
          t('myAccountPage.myList.message.item.success'),
          'alert',
          'success'
        );
        setProductInWishlist(true);
      })
      .catch((e) => {
        messageMutations.addLowAttentionMessage(
          t('myAccountPage.myList.message.item.fail'),
          'alert',
          'warning'
        );
      });
  };
  if (props.loading) {
    return null;
  }
  if (props.error) {
    return (
      <Grid container direction="column">
        <Grid item xs={12}>
          <Button
            color="secondary"
            size="small"
            variant="contained"
            onClick={() => history.push('/login')}
          >
            <FavoriteBorderIcon fontSize="small" />
          </Button>
        </Grid>
      </Grid>
    );
  }
  return (
    <div className={classes.addToWishList}>
      {wishLists?.length != 0 ? (
        <Typography>Add to Wish List</Typography>
      ) : null}
      <Grid container direction="column">
        <Grid item xs={12}>
          <div className={classes.next}>
            <ButtonGroup
              variant="contained"
              color="secondary"
              disabled={!props.productId}
              ref={anchorRef}
              aria-label="split button"
            >
              {wishLists?.length != 0 ? (
                <Button onClick={handleAddToWishList}>
                  {wishLists && wishLists[selectedIndex]
                    ? wishLists[selectedIndex].node.name
                    : null}
                </Button>
              ) : (
                <Button
                  color="secondary"
                  size="small"
                  variant="contained"
                  onClick={() => history.push('/account/my-list')}
                >
                  Add To Wish List
                </Button>
              )}
              <Button
                color="secondary"
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <div className={classes.icon}>
              {isProductInWishList ? (
                <FavoriteIcon color="secondary"></FavoriteIcon>
              ) : (
                <FavoriteBorderIcon color="secondary"></FavoriteBorderIcon>
              )}
            </div>
          </div>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            className={classes.pullForward}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                  zIndex: 2000,
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {wishLists
                        ? wishLists.map((edge: any, index: number) => (
                            <MenuItem
                              key={edge.node.id}
                              selected={index === selectedIndex}
                              onClick={() =>
                                handleMenuItemClick(edge.node.name, index)
                              }
                            >
                              {edge.node.name}
                            </MenuItem>
                          ))
                        : null}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  pullForward: {
    zIndex: 2000,
  },
  addToWishList: {
    display: 'flex',
    flexDirection: 'column',
    flexAlign: 'center',
    maxWidth: 400,
    marginTop: '10px',
  },
  next: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5px',
  },
}));
export default AddItemToList;
