import { gql, useMutation } from '@apollo/client';
import { GET_CART } from './../index';

import get from 'lodash/get';

export const PLACE_ORDER = gql`
  mutation PLACE_ORDER {
    completeCart {
      code
      message
      order {
        id
        orderNumber
        completedCartId
      }
    }
  }
`;

export const usePlaceOrder = () => {
  const [mutate, { data, error }] = useMutation<any>(PLACE_ORDER, {
    update: (cache, { data }) => {
      const completedCartId = get<string | null>(
        data,
        'completeCart.completedCartId',
        null
      );

      if (completedCartId) {
        // We need to clear the cart by removing it from the cache
        cache.writeQuery({
          query: GET_CART,
          data: {
            cart: {},
          },
        });

        // Evict the cart object from the cache
        cache.evict({
          id: `Cart:${completedCartId}`,
        });
        // At this point we will end up with the dangling object in cache
        // which will be the cleared cart. i.e. cart:<cleared_cart_id>
        // Executing garbage collection will remove the dangling object.
        cache.gc();
      } else {
        console.log(`Expected completed cart id, got: ${completedCartId}`);
      }
    },
    refetchQueries: [{ query: GET_CART }],
  });

  return { mutate, data, error };
};
