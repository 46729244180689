import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useMutation } from '@apollo/client';

// src
import { createBrowserHistory } from 'history';
import Routes from './routes';

// Operations
import { REFRESH_TOKEN } from 'operations/mutations/refreshToken';

// Utils
import { setAuthInfo, tryRefreshToken } from 'utils/auth';
import { SiteContext } from 'utils/SiteProvider';

// Material-UI
import CssBaseline from '@material-ui/core/CssBaseline';


interface Props {
	
}

export const history = createBrowserHistory();

const App: React.FC<Props> = (props) => {
	const site:any = useContext(SiteContext);

	// Operations
	const [refreshToken] = useMutation(REFRESH_TOKEN, {
		onCompleted: ({ refreshToken }) => {
			setAuthInfo(refreshToken);
		},
		onError: (error) => {
			console.log('on error refresh token ', error);
		},
		update: (_, result) => {
			console.log('update function of refreshToken ', result);
		},
	});

	useEffect(() => {
		// If user reloads the page and is logged in
		// we need to start watching for for the
		// token refresh
		// const authenticated = props.currentUser;
		//     props.startTokenRefreshWatch();
		// }
		if (site?.session?.isLoggedIn) {
			tryRefreshToken(refreshToken);
		}
	}, [site?.session?.isLoggedIn, refreshToken]);

	return (<>
		<Helmet>
			<title>{site.siteName}</title>
			<meta name="description" content="This is my page description" />
		</Helmet>
		<Router>
			<Routes />
			<CssBaseline />
		</Router>
	</>);
};

export default App;
