import { useHistory } from 'react-router';
import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  List,
  makeStyles,
  Slide,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { MenuNode } from '../../interfaces';
import { useNavigationDispatch } from './navigationContext';

interface Props {
  id: any;
  menuNode: MenuNode;
  tier: number;
  menuJson: MenuNode[];
}

const MobileMenuChild: React.FC<Props> = ({
  id,
  menuNode,
  tier,
  menuJson,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [shouldShowSubMenu, setShouldShowSubMenu] = useState(false);
  const navigationDispatch = useNavigationDispatch();


  /*
  * Finding the children nodes of the parent node and populating the component.
  * hasAnotherNode is important to note here, as it determines if the node has another nested node
  * If greater than 0 then another node is present
  */


  const children = menuJson
    ?.filter((obj: any) => obj.parentId === id)
    .sort((a: any, b: any) => (a.sort > b.sort ? 1 : b.sort > a.sort ? -1 : 0));

  let hasAnotherNode = children.length > 0;

  const handleMenuItemClick = (forceLink?: boolean) => {
    if (!forceLink && hasAnotherNode) {
      setShouldShowSubMenu(true);
    } else {
      // Add menu linking here
      history.push(`/product-list/${menuNode.seoCrumb}`);
      navigationDispatch({ type: 'hideMobileMenu' });
    }
  };

  if (menuNode.parentId === null) {
    return null;
  } else {
    const menu = children?.map((node: any) => {
      return (
        <>
          <MobileMenuChild
            id={node.id}
            menuNode={node}
            menuJson={menuJson}
            key={node.id}
            tier={tier + 1}
          />
        </>
      );
    });

    return (
      <>
        <ListItem button onClick={() => handleMenuItemClick(false)}>
          <ListItemText primary={menuNode.displayName} />
          {hasAnotherNode ? (
            <KeyboardArrowRightIcon className={classes.menuOpenIndicator} />
          ) : null}
        </ListItem>
        <Slide
          direction="left"
          mountOnEnter
          unmountOnExit
          in={shouldShowSubMenu}
        >
          <List className={classes.subMenu} style={{ zIndex: tier }}>
            <ListItem button onClick={() => setShouldShowSubMenu(false)}>
              <KeyboardArrowLeftIcon />
              Back
            </ListItem>

            {menu}
            <ListItem button onClick={() => handleMenuItemClick(true)}>
              <ListItemText primary={`Shop All ${menuNode.displayName}`} />
            </ListItem>
          </List>
        </Slide>
      </>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  subMenu: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'white',
  },
  menuOpenIndicator: {
    display: 'inline-block',
    float: 'right',
    fontSize: '1.5rem',
  },
}));

export default MobileMenuChild;
