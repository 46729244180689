import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';

// Local
import './i18n';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Utils
import { client } from './utils/apolloClient';
import SiteProvider from './utils/SiteProvider';


async function init() {
	ReactDOM.render(
		<ApolloProvider client={client}>
			<SiteProvider>
				<App/>
			</SiteProvider>
		</ApolloProvider>,
		document.getElementById('root')
	);

	// If you want your app to work offline and load faster, you can change
	// unregister() to register() below. Note this comes with some pitfalls.
	// Learn more about service workers: https://bit.ly/CRA-PWA
	serviceWorker.unregister();
}

init();
