import React, { createContext } from 'react';

// Operations
import { useInitialLoad } from 'operations/queries/initalLoad';

// Root
import themeBuilder from 'buildMuiTheme';

// Material UI
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';

const SiteContext = createContext({});

interface Props {
	
};

const SiteProvider: React.FC<Props> = (props) => {
	
	// Operations
	const { site, loading } = useInitialLoad();

	if(loading)
		return null;

	return (
		<MuiThemeProvider theme={themeBuilder(site?.settings?.selectedTheme || {}, site?.settings?.themeOverrides || [])}>
			<SiteContext.Provider value={site}>
				{props.children}
			</SiteContext.Provider>
		</MuiThemeProvider>
	);
};

export { SiteContext };

export default SiteProvider;
