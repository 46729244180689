import React, { useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import UserSession from 'components/userSession';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  AppBar, Box, Container, Toolbar,Typography
} from '@material-ui/core';

import Navigation, {
  NavigationProvider,
  MobileMenuToggle,
} from 'components/navigation';
import GlobalMessages from 'components/GlobalMessages';
import { MenuNode } from '../interfaces';

import Search from './Search';
import MiniCart from './MiniCart';

import Logo from './Logo';
import SoboDrawer from './Sobo/SoboDrawer';
import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { IS_LOGGED_IN } from 'operations/queries/isLoggedIn';

interface Props {
  menuJson: MenuNode[] | null;
}

const Header: React.FC<Props> = ({ menuJson }) => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const [searchOpen, setSearchOpen] = useState(location.pathname === '/search');
  const { data, loading } = useQuery<{ isLoggedIn: boolean }>(IS_LOGGED_IN);
  const appBar = useRef<any>();
  /*
   * When location change close the search box if it is open
   */
  history.listen((_) => {
    if (searchOpen) {
      setSearchOpen(false);
    }
  });

  const toggleSearchMenu = (isOpen: boolean) => setSearchOpen(isOpen);

  return (
    <>
      <Box style={{ position: 'relative', zIndex: 1000 }}>
        <AppBar color="inherit" ref={appBar} elevation={0}>
          <Container className={classes.topBar}>

          </Container>
          <Container maxWidth="lg">
            <Toolbar className={classes.root}>
              {/* <Link to="/" className={classes.logoWrapper}
              >
                <Logo />
              </Link> */}
              <NavigationProvider>
                <div className={classes.navigationProvider}>
                  <div className={classes.logoContainer}>
                  <Link to="/">
                  <img src="https://www.norco.com/_application/views/images/branding/norco-wordmark-black.svg" className={classes.imageLogo}></img>
                  </Link>
                  </div>
                  <Navigation />

                  <div className={classes.menuIcons}>
                    {!searchOpen && <MobileMenuToggle />}
                    <Search
                      isOpen={searchOpen}
                      toggleSearchMenu={toggleSearchMenu}
                      enableTypeahead={true}
                    />
                    {!searchOpen && (
                      <>
                        <UserSession />
                        <MiniCart />

                        {/* TODO: Only checks if users is offline, needs to also check if logged in user has  sobo access */}
                        {data?.isLoggedIn ?
                          <SoboDrawer />
                          : null}

                      </>
                    )}
                  </div>
                </div>
              </NavigationProvider>
            </Toolbar>
          </Container>
        </AppBar>
        <GlobalMessages />
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexWrap: 'wrap',
    paddingTop: theme.spacing(0.8),
    //[theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    //},
  },
  logoWrapper: {
    '@media screen and (max-width: 1100px)': {
      flexGrow: 1,
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
    },
  },
  menuIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 0',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-around',
    },
  },
  topBar: {
    background: theme.palette.secondary.main
  },
  navigationProvider: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr',
    alignItems: 'center',
    width: '100%',
  },
  imageLogo: {
    height: '15px'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}));

export default Header;
